import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import statusImage from "../assets/images/status/error.svg"
import Footer from "../components/App/Footer"
const Error = () => {
  return (
    <Layout>
      <Navbar />

      <section className="pt-70 pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4 mx-md-auto text-center">
              <h1 className="display-1 center-xy text-black-50 bold">403</h1>
              <img
                src={statusImage}
                class="img-responsive w-70 mx-auto"
                alt="404"
              />
              <h1 className="bold text-danger mt-5">Unauthorized</h1>
              <p className="text-secondary">
                Sorry, you're not allowed to execute this action.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export default Error
